import React from "react";
import "./experience.css";
import { MdVerified } from "react-icons/md";
const Experience = () => {
  return (
    <section id="experience">
      <h5>Skills</h5>
      <h2>My experience</h2>
      <div className="container experience__container">
        <div className="experience__tools">
          <h3>Tools</h3>
          <div className="experience__content">
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Laravel</h4>
                <small className="text-light">Beginner</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>PHP Unit</h4>
                <small className="text-light">Beginner</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>R</h4>
                <small className="text-light">Beginner</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>ReactJS</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>React Native</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Python</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>JavaScript</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Android studio</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>C++/C#</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>HTML</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>CSS</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>PHP</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Firebase</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Java</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>MySQL</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>SQL Server</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Selenium</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__achievements">
          <h3>Achievements</h3>
          <div className="experience__content">
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>CCNA</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>CCNA Security</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Basics of user experience design</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Business Intelligence</h4>
                <small className="text-light">Diplomat</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Agile Meets Design Thinking</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Hypothesis-Driven Development</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>API Testing a real web application via Postman</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>API Testing with Karate Framework</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Advanced TestNG Framework and Integration with Selenium</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>
                  Automate an e-commerce web application using Selenium & Java
                </h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>
                  Building Test Automation Framework using Selenium and TestNG
                </h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Configuring your IDE for beginners with IntelliJ IDEA</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>
                  Data-Driven Testing (via Database) with Selenium & TestNG
                </h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>How to create an API request using Postman</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Introduction to TestNG and Integration with Selenium</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>
                  Master XPath, Css Selector, and Other Locators in Selenium
                </h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Selenium Grid - Running Selenium tests in parallel</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
