import React from "react";
import { useState } from "react";
import "./nav.css";
import { BsHouseFill, BsFillBookFill } from "react-icons/bs";
import { RiServiceFill, RiContactsFill, RiMessage3Fill } from "react-icons/ri";
const Nav = () => {
  const [activeNav, setActiveNav] = useState("/#");
  return (
    <nav>
      <a
        href="/#"
        onClick={() => setActiveNav("/#")}
        className={activeNav === "/#" ? "active" : ""}
      >
        <BsHouseFill />
      </a>
      <a
        href="#about"
        onClick={() => setActiveNav("#about")}
        className={activeNav === "#about" ? "active" : ""}
      >
        <RiContactsFill />
      </a>
      <a href="#experience" onClick={() => setActiveNav("#experience")}>
        <BsFillBookFill />
      </a>
      <a
        href="#services"
        onClick={() => setActiveNav("#services")}
        className={activeNav === "#services" ? "active" : ""}
      >
        <RiServiceFill />
      </a>
      <a
        href="#contact"
        onClick={() => setActiveNav("#contact")}
        className={activeNav === "#contact" ? "active" : ""}
      >
        <RiMessage3Fill />
      </a>
    </nav>
  );
};

export default Nav;
