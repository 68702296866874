import React from "react";
import "./footer.css";
import {
  BsLinkedin,
  BsGithub,
  BsTwitch,
  BsInstagram,
  BsTwitter,
} from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
const Footer = () => {
  return (
    <footer>
      <a href="/#" className="footer__logo">
        Sebastián VaRom
      </a>
      <ul className="permalinks">
        <li>
          <a href="/#">Home</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#services">Services</a>
        </li>

        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/sebastian-varom/" rel="external">
          <BsLinkedin />
        </a>
        <a href="https://github.com/SVaRom" rel="external">
          <BsGithub />
        </a>
        <a href="https://www.twitch.tv/sebastian_varom" rel="external">
          <BsTwitch />
        </a>
        <a href="https://www.instagram.com/varom.bin/" rel="external">
          <BsInstagram />
        </a>
        <a href="https://twitter.com/SebastianVaRom" rel="external">
          <BsTwitter />
        </a>
        <a href="https://www.tiktok.com/@varom.bin" rel="external">
          <FaTiktok />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
