import React from "react";
import "./portfolio.css";

const data = [
  {
    image: (
      <iframe
        src="https://www.youtube.com/embed/TMrvsVY4h8Y?si=F_3ns8dqMeEB7mkM"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    ),
    title: "LearnApp",
    github: "https://github.com/SVaRom/LearnApp",
  },
  {
    image: (
      <iframe
        src="https://www.youtube.com/embed/HimWWTkE_XQ?si=y52nnMppAjgp7oTo"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    ),
    title: "Meetings CRUD",
    github: "https://github.com/SVaRom/meetings-crud",
  },
  {
    image: (
      <iframe
        src="https://www.youtube.com/embed/OhXCH6NtQPA?si=-92F-EZF7zHIvf4Z"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    ),
    title: "TicTacToe Android",
    github: "https://github.com/SVaRom/TicTacToe",
  },
  {
    image: (
      <iframe
        src="https://www.youtube.com/embed/pu7Qrc31h3I?si=Ojok0Z8WJjQg7da8"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    ),
    title: "ABC System",
    github: "https://github.com/SVaRom/Sistema-Truper",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My recent work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {data.map(({ image, title, github }, index) => {
          return (
            <article key={index} className="portfolio__items">
              <center>
                <div className="portfolio__items-image">{image}</div>
              </center>
              <h3>{title}</h3>
              <div className="portfolio__items-cta">
                <a href={github} className="btn" rel="external">
                  Github
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
