import React from "react";
import {
  BsLinkedin,
  BsGithub,
  BsTwitch,
  BsInstagram,
  BsTwitter,
} from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
function HeaderSocials() {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/sebastian-varom/" rel="external">
        <BsLinkedin />
      </a>
      <a href="https://github.com/SVaRom" rel="external">
        <BsGithub />
      </a>
      <a href="https://www.twitch.tv/sebastian_varom" rel="external">
        <BsTwitch />
      </a>
      <a href="https://www.instagram.com/varom.bin/" rel="external">
        <BsInstagram />
      </a>
      <a href="https://twitter.com/SebastianVaRom" rel="external">
        <BsTwitter />
      </a>
      <a href="https://www.tiktok.com/@varom.bin" rel="external">
        <FaTiktok />
      </a>
    </div>
  );
}

export default HeaderSocials;
