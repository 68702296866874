import React, { useRef } from "react";
import "./contact.css";
import { BsMailbox, BsInfoCircleFill } from "react-icons/bs";
import emailjs from "emailjs-com";
const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_8ucxk0p",
      "template_gv81mcj",
      form.current,
      "O2LIm1LBcr3gzee34"
    );
    e.target.reset();
  };
  return (
    <section id="contact">
      <h5>Get in touch</h5>
      <h2>Contact me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <BsMailbox className="contact__option-icon" />
            <h4>Email</h4>
            <h5>sebastian.varom13@gmail.com</h5>
            <a href="mailto:sebastian.varom13@gmail.com">Send a message</a>
          </article>
          <article className="contact__option">
            <BsInfoCircleFill className="contact__option-icon" />
            <h4>Don't be shy</h4>
            <h5>Send me an email</h5>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="your@email.com"
            required
          />
          <textarea
            name="message"
            cols="30"
            rows="7"
            placeholder="Your message"
            required
          />
          <button type="submit" className="btn btn-primary">
            Send message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
